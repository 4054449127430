<template>
    <div class="content-box">
        <div class="alert alert-success time-track">
            Local time: {{ localTime }}<br>
            <strong>UTC Time: {{ utcTime }}</strong>
        </div>
        <b-form @submit.prevent="onSubmit">
            <b-row>
                <b-col cols-lg="6">
                    <b-form-group
                        :class="{ row, error: v$.stationSelect.$errors.length }"
                        :label="$t('forms.station')"
                        label-for="stationSelect"
                        label-cols-md="4"
                        content-cols-lg="8"
                        v-if="showStation()"
                    >
                        <v-select
                            id="stationSelect"
                            v-model="stationSelect"
                            :options="stations"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.stationSelect.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: v$.customerSelect.$errors.length }"
                        :label="$t('forms.customer')"
                        label-for="customer"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <v-select
                            id="customer"
                            v-model="customerSelect"
                            :options="customers"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.customerSelect.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: v$.ocr.dateOfAssistance.$errors.length }"
                        :label="$t('forms.dateOfAssistance')+' *1'"
                        label-for="dateOfAssistance"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <datepicker
                            id="dateOfAssistance"
                            v-model="ocr.dateOfAssistance"
                            :enableTimePicker="true"
                            class="form-input"
                            format="dd.MM.yyyy HH:mm"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.ocr.dateOfAssistance.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        class="row"
                        :label="$t('forms.flightNumber') + ' *2'"
                        label-for="flightNumber"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-input v-model="ocr.flightNumber" id="flightNumber"/>
                        <div
                            class="input-errors"
                            v-for="error of v$.ocr.flightNumber.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        class="row"
                        :label="$t('forms.eta')"
                        label-for="eta"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <datepicker
                            id="eta"
                            v-model="ocr.eta"
                            :enableTimePicker="true"
                            class="form-input"
                            format="dd.MM.yyyy HH:mm"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.ocr.eta.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        class="row"
                        :label="$t('forms.ata')"
                        label-for="ata"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <datepicker
                            id="ata"
                            v-model="ocr.ata"
                            :timePickerInline="true"
                            class="form-input"
                            format="dd.MM.yyyy HH:mm"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.ocr.ata.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        class="row"
                        :label="$t('forms.timeOfCall') + ' *4'"
                        label-for="timeOfCall"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <datepicker
                            id="timeOfCall"
                            v-model="ocr.timeOfCall"
                            :enableTimePicker="true"
                            class="form-input"
                            format="dd.MM.yyyy HH:mm"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.ocr.timeOfCall.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        class="row"
                        :label="$t('forms.timeOfShowing') + ' *5'"
                        label-for="timeOfShowing"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <datepicker
                            id="timeOfShowing"
                            v-model="ocr.timeOfShowing"
                            :enableTimePicker="true"
                            class="form-input"
                            format="dd.MM.yyyy HH:mm"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.ocr.timeOfShowing.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        class="row"
                        :label="$t('forms.timeOfCSRIssue') + ' *6'"
                        label-for="timeOfCSRIssue"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <datepicker
                            id="timeOfCSRIssue"
                            v-model="ocr.timeOfCSRIssue"
                            :enableTimePicker="true"
                            class="form-input"
                            format="dd.MM.yyyy HH:mm"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.ocr.timeOfCSRIssue.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        class="row"
                        :label="$t('forms.etd') + ' *3'"
                        label-for="etd"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <datepicker
                            id="etd"
                            v-model="ocr.etd"
                            :enableTimePicker="true"
                            class="form-input"
                            format="dd.MM.yyyy HH:mm"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.ocr.etd.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        class="row"
                        :label="$t('forms.timeOfDeparture') + ' *7'"
                        label-for="timeOfDeparture"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <datepicker
                            id="timeOfDeparture"
                            v-model="ocr.timeOfDeparture"
                            :enableTimePicker="true"
                            class="form-input"
                            format="dd.MM.yyyy HH:mm"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.ocr.timeOfDeparture.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                </b-col>
                <b-col cols-lg="6">
                    <b-form-group
                        :class="{ row, error: v$.acTypeSelect.$errors.length }"
                        :label="$t('forms.acType')"
                        label-for="acTypeSelect"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <v-select
                            id="acTypeSelect"
                            v-model="acTypeSelect"
                            :options="acTypes"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.acTypeSelect.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>

                    <b-form-group
                        :class="{ row, error: v$.ocr.acRegistration.$errors.length }"
                        :label="$t('forms.acRegistration')"
                        label-for="acRegistration"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-input v-model="ocr.acRegistration" id="acRegistration"/>
                        <div
                            class="input-errors"
                            v-for="error of v$.ocr.acRegistration.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: v$.ocr.woReference.$errors.length }"
                        :label="$t('forms.woReference')"
                        label-for="woReference"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-input v-model="ocr.woReference" id="woReference"/>
                        <div
                            class="input-errors"
                            v-for="error of v$.ocr.woReference.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        class="row"
                        :label="$t('forms.manHoursUsed')"
                        label-for="manHoursUsed"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <datepicker
                            id="manHoursUsed"
                            v-model="ocr.manHoursUsed"
                            timePicker
                            :startTime="{ hours: 0, minutes: 0 }"
                            class="form-input"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.ocr.manHoursUsed.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: v$.ocr.ataChapter.$errors.length }"
                        :label="$t('forms.ataChapter')"
                        label-for="ataChapter"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-input v-model="ocr.ataChapter" id="ataChapter"/>
                        <div
                            class="input-errors"
                            v-for="error of v$.ocr.ataChapter.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: v$.ocr.description.$errors.length }"
                        :label="$t('forms.description')"
                        label-for="description"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-textarea v-model="ocr.description" id="description"/>
                        <div
                            class="input-errors"
                            v-for="error of v$.ocr.description.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: v$.ocr.preventiveAction.$errors.length }"
                        :label="$t('forms.preventiveAction')"
                        label-for="preventiveAction"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-textarea
                            v-model="ocr.preventiveAction"
                            id="preventiveAction"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.ocr.preventiveAction.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                </b-col>
            </b-row>
            <hr>
            <small>
                <sup>*1</sup> Date of assistance<br>
                <sup>*2</sup> Flight afrer assistance<br>
                <sup>*3</sup> Estimated time of departure according to flight plan<br>
                <sup>*4</sup> Time of phone call from MCC or time of maintenance order<br>
                <sup>*5</sup> Moment of speaking with captain or reading the ATL entry<br>
                <sup>*6</sup> Regarding the work order time (Time of transfer or Time of work order performed,
                closed)<br>
                <sup>*7</sup> Actual time of departure according to airport time table
            </small>
        </b-form>
    </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import ResourceUtils from "@/mixins/ResourceUtils";
import ListUtils from "@/mixins/ListUtils";
import "@vuepic/vue-datepicker/dist/main.css";
import vSelect from "vue-select";
import Datepicker from "@vuepic/vue-datepicker";
import {getUserData} from "@/auth/utils";

export default {
    components: {
        vSelect,
        Datepicker,
    },
    props: {
        ocrObject: {Object, required: true},
        action: {type: String, default: null},
    },
    mixins: [ResourceUtils, ListUtils],
    created() {
        if(this.$helper.userAdditionalRole(process.env.VUE_APP_ROLE_ENGINEER)) {
            this.stationSelect['id'] = getUserData()['station']['@id']
        }
        clearInterval(this.localTimerInstance)
        clearInterval(this.utcTimerInstance)
        const _self = this
        this.localTimerInstance = setInterval(function () {
            const currentDate = new Date()
            _self.localTime = new Intl.DateTimeFormat('en', {
                minute: '2-digit',
                hour: '2-digit',
                hourCycle: 'h23'
            }).format(currentDate);
        }, 1000)
        this.utcTimerInstance = setInterval(function () {
            const currentDate = new Date()
            let hours = currentDate.getUTCHours()
            let minutes = currentDate.getUTCMinutes()
            if (hours < 10) {
                hours = "0" + hours
            }
            if (minutes < 10) {
                minutes = "0" + minutes
            }
            _self.utcTime = hours + ':' + minutes
        }, 1000)
        this.getUserList({params: {role: "ROLE_CUSTOMER"}}, this.customers);
        this.getResourceList(this.$Stations, this.stations);
        this.getResourceList(this.$AcTypes, this.acTypes);
        if ("id" in this.ocr) {
            this.stationSelect = {
                label: this.ocr.station.name,
                id: this.ocr.station["@id"],
            };
            this.acTypeSelect = {
                label: this.ocr.acType.name,
                id: this.ocr.acType["@id"],
            };
            this.customerSelect = {
                label: this.ocr.customer.customer,
                id: this.ocr.customer["@id"],
            };
            if (this.ocr.manHoursUsed) {
                const time = this.ocr.manHoursUsed.split(':')
                this.ocr.manHoursUsed = {}
                this.ocr.manHoursUsed.hours = time[0]
                this.ocr.manHoursUsed.minutes = time[1]
                this.ocr.manHoursUsed.seconds = 0
            }
        }
    },
    setup: () => ({v$: useVuelidate()}),
    validations() {
        return {
            stationSelect: {required},
            customerSelect: {required},
            acTypeSelect: {required},
            ocr: {
                dateOfAssistance: {required},
                timeOfCall: {required},
                timeOfCSRIssue: {required},
                timeOfDeparture: {required},
                timeOfShowing: {required},
                etd: {required},
                eta: {required},
                ata: {required},
                flightNumber: {required},
                manHoursUsed: {required},
                acRegistration: {required},
                woReference: {required},
                ataChapter: {required},
                description: {required},
                preventiveAction: {required}
            },
        };
    },
    data() {
        return {
            customers: [],
            customerSelect: {},
            ocr: this.ocrObject,
            stationSelect: {},
            stations: [],
            acTypeSelect: {},
            acTypes: [],
            localTimerInstance: null,
            utcTimerInstance: null,
            localTime: null,
            utcTime: null
        };
    },
    watch: {
        action() {
            this.onSubmit();
        },
    },
    methods: {
        async onSubmit() {
            const isValid = await this.v$.$validate();
            if (isValid) {
                if (this.acTypeSelect) {
                    this.ocr.acType = this.acTypeSelect.id;
                }
                
                if (this.stationSelect) {
                    this.ocr.station = this.stationSelect.id;
                }
                if (this.customerSelect) {
                    this.ocr.customer = this.customerSelect.id;
                }
                if (this.ocr.manHoursUsed) {
                    this.ocr.manHoursUsed = this.ocr.manHoursUsed.hours + ':' + this.ocr.manHoursUsed.minutes
                }
                if (this.ocr.eta) {
                    this.ocr.eta = this.$helper.formatDateTimeToSend(this.ocr.eta)
                }
                if (this.ocr.etd) {
                    this.ocr.etd = this.$helper.formatDateTimeToSend(this.ocr.etd)
                }
                if (this.ocr.ata) {
                    this.ocr.ata = this.$helper.formatDateTimeToSend(this.ocr.ata)
                }
                if (this.ocr.dateOfAssistance) {
                    this.ocr.dateOfAssistance = this.$helper.formatDateTimeToSend(this.ocr.dateOfAssistance)
                }
                if (this.ocr.timeOfCall) {
                    this.ocr.timeOfCall = this.$helper.formatDateTimeToSend(this.ocr.timeOfCall)
                }
                if (this.ocr.timeOfCSRIssue) {
                    this.ocr.timeOfCSRIssue = this.$helper.formatDateTimeToSend(this.ocr.timeOfCSRIssue)
                }
                if (this.ocr.timeOfDeparture) {
                    this.ocr.timeOfDeparture = this.$helper.formatDateTimeToSend(this.ocr.timeOfDeparture)
                }
                if (this.ocr.timeOfShowing) {
                    this.ocr.timeOfShowing = this.$helper.formatDateTimeToSend(this.ocr.timeOfShowing)
                }
                if (this.action === "add") {
                    this.create(
                        this.$Ocrs,
                        this.ocr,
                        this.$t("messages.ocrCreated"),
                        null,
                        this.successCreate,
                        this.error
                    );
                }
                if (this.action === "update") {
                    this.$emit("clearAction");
                    this.update(
                        this.$Ocrs,
                        this.ocr.id,
                        this.ocr,
                        this.$t("messages.ocrUpdated"),
                        null,
                        this.success,
                        this.error
                    );
                }
            } else {
                this.$emit("clearAction");
            }
        },
        showStation() {
            return !this.$helper.userAdditionalRole(process.env.VUE_APP_ROLE_ENGINEER)
        },
        success() {
            this.$router.push(`/ocr`);
        },
        successCreate(response) {
            const path = '/ocr/' + response.data.id + '/edit';

            this.$router.push({path, query: {files: 'true'}});
        },
        error() {
            this.$emit("clearAction");
        },
    },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>

